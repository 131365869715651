import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-section',
  templateUrl: './product-section.component.html',
  styleUrls: ['./product-section.component.scss']
})
export class ProductSectionComponent implements OnInit {

  @Output("viewAllButtonClick") viewAllButtonClick: EventEmitter<any> = new EventEmitter();

  totalProducts = [
    {
      "products" : [
        {
          title: "Bolts",
          imgSource: "Bolt.png",
          productCount: ""
        },
        {
          title: "Screws",
          imgSource: "Screw.png",
          productCount: ""
        },
        {
          title: "Nuts",
          imgSource: "Nut.png",
          productCount: ""
        }
      ]
    },
    {
      "products" : [
        {
          title: "Washer",
          imgSource: "Washer.png",
          productCount: ""
        },
        {
          title: "HDG",
          imgSource: "HDG-Nut.png",
          productCount: ""
        },
        {
          title: "Circlip",
          imgSource: "Circlip.png",
          productCount: ""
        }
      ]
    },
    {
      "products" : [
        {
          title: "Plugs",
          imgSource: "Plug.png",
          productCount: ""
        },
        {
          title: "Grease Nipple",
          imgSource: "Grease Nipple.png",
          productCount: ""
        },
        {
          title: "Self Tapping Screw",
          imgSource: "Self Tapping Screw.png",
          productCount: ""
        }
      ]
    },
    {
      "products" : [
        {
          title: "Pin",
          imgSource: "Pin.png",
          productCount: ""
        }
      ]
    }
  ]

  

  constructor() { }

  onViewAllClick(selectedProductName: string) {
    console.log(" selected Product - ",  selectedProductName);
    this.viewAllButtonClick.emit(selectedProductName)
  }

  ngOnInit(): void {
  }

}
