<div class="product-carousel-container">
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
  >
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <div class="product-box">
        <a routerLink="/products/{{slide.prodCategory}}" routerLinkActive="active">
          <div class="img-area">
            <img src="assets/images/{{ slide.imgSrc }}" class="img-fluid rounded" alt="" />
          </div>
        </a>
      </div>
      <div class="img-text">
        <span>{{ slide.title }}</span>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
