<div class="products-group-section">
  <div class="row" *ngFor="let productSet of totalProducts">
    <div class="col-12 col-sm-4 col-md-4 col-lg-4" *ngFor="let item of productSet.products">
      <div class="card">
        <div class="card-body text-center">
          <div class="img-area" (click)="onViewAllClick(item.title)">
            <img
              class="img-fluid rounded"
              src="assets/images/products/{{item.imgSource}}"
              alt="{{item.title}}"
            />
          </div>
          <div class="card-title">
            <div class="row title-item nomargin">
              <div class="col-12 col-sm-12 col-md-12 col-lg-8 nopadding"><div class="card-title-text">{{ item.title }}</div></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 colnopadding">
                <div class="card-btn">
                  <button class="btn btn-primary btn-sm" (click)="onViewAllClick(item.title)">View All</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
