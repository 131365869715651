import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})
export class ProductCarouselComponent implements OnInit {

  slides = [
    {
      title: "Eye Nut",
      imgSrc: "banner_images/Eye Nut.png",
      prodCategory: "Nuts"
    },
    {
      title: "Drain Plug",
      imgSrc: "banner_images/Drain Plug.png",
      prodCategory: "Plugs"
    },
    {
      title: "Set Screw",
      imgSrc: "banner_images/Set Screw.png",
      prodCategory: "Screws"
    },
    {
      title: "Circlip",
      imgSrc: "banner_images/Circlip.png",
      prodCategory: "Circlip"
    },
    {
      title: "Eye Bolt",
      imgSrc: "banner_images/Eye Bolt.png",
      prodCategory: "Bolts"
    },
    {
      title: "Grease Nipples",
      imgSrc: "banner_images/Grease Nipples.png",
      prodCategory: "Grease Nipple"
    },
    {
      title: "Nut",
      imgSrc: "banner_images/Nut.png",
      prodCategory: "Nuts"
    },
    {
      title: "Nyloc Nut",
      imgSrc: "banner_images/Nyloc Nut.png",
      prodCategory: "Nuts"
    },
    {
      title: "Socket Head Bolt",
      imgSrc: "banner_images/Socket Head Bolt.png",
      prodCategory: "Bolts"
    },
    {
      title: "Spring Washer",
      imgSrc: "banner_images/Spring Washer.png",
      prodCategory: "Washer"
    },
    {
      title: "Washer",
      imgSrc: "banner_images/Washer.png",
      prodCategory: "Washer"
    },
    {
      title: "Wing Nut",
      imgSrc: "banner_images/Wing Nut.png",
      prodCategory: "Nuts"
    },
    {
      title: "Screws",
      imgSrc: "banner_images/Screws.png",
      prodCategory: "Screws"
    },
    {
      title: "Self Drilling Screws",
      imgSrc: "banner_images/Self Drilling Screws.png",
      prodCategory: "Screws"
    }
  ];

  slideConfig = {
    autoplay: true,
    arrows: true,
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "infinite": true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() {}

  slickInit(event: any) {
    console.log('slick initialized');
  }

  ngOnInit() {
  }

}
